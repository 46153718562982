import React, { act, useMemo, useState } from 'react';
import { useAlbumsWithItems, useShareAlbum } from "../queries";
import ItemGrid from '../gallery/item.grid';
import { TopBar } from '../common/top.bar';
import { useNavigate, useSearch } from '@tanstack/react-router';
import { EditPencil, Link, ShareIos, Trash } from 'iconoir-react';
import { DeleteAlbumModal } from './delete.album.modal';
import { EditAlbumModal } from './edit.album.modal';
import { ActionMenu } from '../common/action.menu';
import { compactGUID } from '../common/format.helpers';
import { Ellipsis } from '../common/ellipsis';
import { router } from '../routes';
import toast from 'react-hot-toast';

interface SearchParams {
    albumId?: number;
}

export const AlbumLayout = () => {
    const navigate = useNavigate();
    const shareAlbumMutation = useShareAlbum();

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [actionMenuActive, setActionMenuActive] = useState(false);
    const params: SearchParams = useSearch({ strict: false });

    // TODO: Use params instead of search
    if (!params.albumId) {
        throw new Error('AlbumId must be set');
    }

    const albums = useAlbumsWithItems() ?? [];
    const album = useMemo(() => {
        return albums.find(_ => _.albumId === params.albumId)
    }, [albums, params.albumId])

    if (!album) {
        return;
    }

    const getPublicLink = async () => {
        const tenantId = localStorage.getItem('tenantId');
        if (!tenantId) {
            return;
        }

        const shareSecret = await shareAlbumMutation.mutateAsync(album.albumId);

        const link = router.buildLocation({
            to: '/p/a/$shortTenantId/$shortShareSecret',
            params: {
                shortTenantId: compactGUID(tenantId),
                shortShareSecret: compactGUID(shareSecret)
            }
        });

        const url = window.location.origin + link.href;

        return url;
    }

    const sharePublicLink = async () => {
        const url = await getPublicLink();

        if (!!navigator.share) {
            navigator.share({ url });
        } else {
            toast.error('Your Web Browser does not support sharing.');
        }
    }

    const openPublicLink = async () => {
        const url = await getPublicLink();
        window.open(url, '_blank');
    }

    const sharingSupported = !!navigator.share;

    let actionOptions = [
        {
            title: 'Open Public Link',
            onClick: () => openPublicLink(),
            visible: true,
            icon: Link
        },
        {
            title: 'Share Public Link',
            onClick: () => sharePublicLink(),
            visible: sharingSupported,
            icon: ShareIos
        },
        {
            title: 'Edit',
            onClick: () => setShowEditModal(true),
            visible: true,
            icon: EditPencil
        },
        {
            title: 'Delete',
            onClick: () => setShowDeleteModal(true),
            icon: Trash,
            visible: true,
            className: 'text-red-500'
        },
    ];

    actionOptions = actionOptions.filter(_ => _.visible);

    return (
        <div className='flex flex-auto flex-col overflow-hidden'>
            <TopBar
                title={album.name}
                rightButtons={[
                    {
                        icon: Ellipsis,
                        className: 'text-slate-900',
                        onClick: () => setActionMenuActive(true),
                        children: (
                            <ActionMenu
                                isOpen={actionMenuActive}
                                onActionStarted={() => setActionMenuActive(false)}
                                onDismiss={() => setActionMenuActive(false)}
                                position='bottom'
                                options={actionOptions}
                            />
                        )
                    }
                ]}
                onTitleClick={() => setShowEditModal(true)}
            />
            <ItemGrid items={album.items} albumId={params.albumId} />
            <DeleteAlbumModal
                album={album}
                isOpen={showDeleteModal}
                onCancel={() => setShowDeleteModal(false)}
                onDeleteComplete={() => {
                    setShowDeleteModal(false);
                    navigate({ to: '/albums' });
                }}
            />
            <EditAlbumModal
                album={album}
                isOpen={showEditModal}
                onCancel={() => setShowEditModal(false)}
                onEditComplete={() => {
                    setShowEditModal(false);
                }}
            />
        </div>
    );
}

