import React from 'react';
import { ZoomIn, ZoomOut } from 'iconoir-react';

interface Props {
    onZoomIn: Function;
    onZoomOut: Function;
}

export const ZoomButtons = ({ onZoomIn, onZoomOut }: Props) => {

    return (
        <div className='flex bg-slate-100 rounded-full drop-shadow overflow-hidden'>
            <div
                className='p-3 hover:bg-slate-200 active:bg-slate-300'
                onClick={() => onZoomIn()}
            >
                <ZoomIn
                    className='size-6'
                />
            </div>
            <div
                className='p-3 hover:bg-slate-200 active:bg-slate-300'
                onClick={() => onZoomOut()}
            >
                <ZoomOut
                    className='size-6'
                />
            </div>
        </div>
    );
};
